import { render, staticRenderFns } from "./DataViewSidebar.vue?vue&type=template&id=72f496b6&scoped=true&"
import script from "./DataViewSidebar.vue?vue&type=script&lang=js&"
export * from "./DataViewSidebar.vue?vue&type=script&lang=js&"
import style0 from "./DataViewSidebar.vue?vue&type=style&index=0&id=72f496b6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72f496b6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/node/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('72f496b6')) {
      api.createRecord('72f496b6', component.options)
    } else {
      api.reload('72f496b6', component.options)
    }
    module.hot.accept("./DataViewSidebar.vue?vue&type=template&id=72f496b6&scoped=true&", function () {
      api.rerender('72f496b6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ui-elements/data-list/DataViewSidebar.vue"
export default component.exports