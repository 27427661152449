var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "data-list-container",
      attrs: { id: "data-list-list-view" },
    },
    [
      _c("data-view-sidebar", {
        attrs: {
          isSidebarActive: _vm.addNewDataSidebar,
          data: _vm.sidebarData,
        },
        on: { closeSidebar: _vm.toggleDataSidebar },
      }),
      _c(
        "vs-table",
        {
          ref: "table",
          attrs: {
            multiple: "",
            pagination: "",
            "max-items": _vm.itemsPerPage,
            search: "",
            data: _vm.products,
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ data }) {
                return [
                  _c(
                    "tbody",
                    _vm._l(data, function (tr, indextr) {
                      return _c(
                        "vs-tr",
                        { key: indextr, attrs: { data: tr } },
                        [
                          _c("vs-td", [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "product-name font-medium truncate",
                              },
                              [_vm._v(_vm._s(tr.name))]
                            ),
                          ]),
                          _c("vs-td", [
                            _c("p", { staticClass: "product-category" }, [
                              _vm._v(_vm._s(_vm._f("title")(tr.category))),
                            ]),
                          ]),
                          _c(
                            "vs-td",
                            [
                              _c("vs-progress", {
                                staticClass: "shadow-md",
                                attrs: {
                                  percent: Number(tr.popularity),
                                  color: _vm.getPopularityColor(
                                    Number(tr.popularity)
                                  ),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "vs-td",
                            [
                              _c(
                                "vs-chip",
                                {
                                  staticClass: "product-order-status",
                                  attrs: {
                                    color: _vm.getOrderStatusColor(
                                      tr.order_status
                                    ),
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm._f("title")(tr.order_status))
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("vs-td", [
                            _c("p", { staticClass: "product-price" }, [
                              _vm._v("$" + _vm._s(tr.price)),
                            ]),
                          ]),
                          _c(
                            "vs-td",
                            { staticClass: "whitespace-no-wrap" },
                            [
                              _c("feather-icon", {
                                attrs: {
                                  icon: "EditIcon",
                                  svgClasses:
                                    "w-5 h-5 hover:text-primary stroke-current",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.editData(tr)
                                  },
                                },
                              }),
                              _c("feather-icon", {
                                staticClass: "ml-2",
                                attrs: {
                                  icon: "TrashIcon",
                                  svgClasses:
                                    "w-5 h-5 hover:text-danger stroke-current",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.deleteData(tr.id)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v
            },
            expression: "selected",
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "flex flex-wrap-reverse items-center flex-grow justify-between",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "flex flex-wrap-reverse items-center data-list-btn-container",
                },
                [
                  _c(
                    "vs-dropdown",
                    {
                      staticClass: "dd-actions cursor-pointer mr-4 mb-4",
                      attrs: { "vs-trigger-click": "" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full",
                        },
                        [
                          _c("span", { staticClass: "mr-2" }, [
                            _vm._v("Actions"),
                          ]),
                          _c("feather-icon", {
                            attrs: {
                              icon: "ChevronDownIcon",
                              svgClasses: "h-4 w-4",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "vs-dropdown-menu",
                        [
                          _c("vs-dropdown-item", [
                            _c(
                              "span",
                              { staticClass: "flex items-center" },
                              [
                                _c("feather-icon", {
                                  staticClass: "mr-2",
                                  attrs: {
                                    icon: "TrashIcon",
                                    svgClasses: "h-4 w-4",
                                  },
                                }),
                                _c("span", [_vm._v("Delete")]),
                              ],
                              1
                            ),
                          ]),
                          _c("vs-dropdown-item", [
                            _c(
                              "span",
                              { staticClass: "flex items-center" },
                              [
                                _c("feather-icon", {
                                  staticClass: "mr-2",
                                  attrs: {
                                    icon: "ArchiveIcon",
                                    svgClasses: "h-4 w-4",
                                  },
                                }),
                                _c("span", [_vm._v("Archive")]),
                              ],
                              1
                            ),
                          ]),
                          _c("vs-dropdown-item", [
                            _c(
                              "span",
                              { staticClass: "flex items-center" },
                              [
                                _c("feather-icon", {
                                  staticClass: "mr-2",
                                  attrs: {
                                    icon: "FileIcon",
                                    svgClasses: "h-4 w-4",
                                  },
                                }),
                                _c("span", [_vm._v("Print")]),
                              ],
                              1
                            ),
                          ]),
                          _c("vs-dropdown-item", [
                            _c(
                              "span",
                              { staticClass: "flex items-center" },
                              [
                                _c("feather-icon", {
                                  staticClass: "mr-2",
                                  attrs: {
                                    icon: "SaveIcon",
                                    svgClasses: "h-4 w-4",
                                  },
                                }),
                                _c("span", [_vm._v("Another Action")]),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary",
                      on: { click: _vm.addNewData },
                    },
                    [
                      _c("feather-icon", {
                        attrs: { icon: "PlusIcon", svgClasses: "h-4 w-4" },
                      }),
                      _c(
                        "span",
                        { staticClass: "ml-2 text-base text-primary" },
                        [_vm._v("Add New")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "vs-dropdown",
                {
                  staticClass:
                    "cursor-pointer mb-4 mr-4 items-per-page-handler",
                  attrs: { "vs-trigger-click": "" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium",
                    },
                    [
                      _c("span", { staticClass: "mr-2" }, [
                        _vm._v(
                          _vm._s(
                            _vm.currentPage * _vm.itemsPerPage -
                              (_vm.itemsPerPage - 1)
                          ) +
                            " - " +
                            _vm._s(
                              _vm.products.length -
                                _vm.currentPage * _vm.itemsPerPage >
                                0
                                ? _vm.currentPage * _vm.itemsPerPage
                                : _vm.products.length
                            ) +
                            " of " +
                            _vm._s(_vm.queriedItems)
                        ),
                      ]),
                      _c("feather-icon", {
                        attrs: {
                          icon: "ChevronDownIcon",
                          svgClasses: "h-4 w-4",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "vs-dropdown-menu",
                    [
                      _c(
                        "vs-dropdown-item",
                        {
                          on: {
                            click: function ($event) {
                              _vm.itemsPerPage = 4
                            },
                          },
                        },
                        [_c("span", [_vm._v("4")])]
                      ),
                      _c(
                        "vs-dropdown-item",
                        {
                          on: {
                            click: function ($event) {
                              _vm.itemsPerPage = 10
                            },
                          },
                        },
                        [_c("span", [_vm._v("10")])]
                      ),
                      _c(
                        "vs-dropdown-item",
                        {
                          on: {
                            click: function ($event) {
                              _vm.itemsPerPage = 15
                            },
                          },
                        },
                        [_c("span", [_vm._v("15")])]
                      ),
                      _c(
                        "vs-dropdown-item",
                        {
                          on: {
                            click: function ($event) {
                              _vm.itemsPerPage = 20
                            },
                          },
                        },
                        [_c("span", [_vm._v("20")])]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "thead" },
            [
              _c("vs-th", { attrs: { "sort-key": "name" } }, [_vm._v("Name")]),
              _c("vs-th", { attrs: { "sort-key": "category" } }, [
                _vm._v("Category"),
              ]),
              _c("vs-th", { attrs: { "sort-key": "popularity" } }, [
                _vm._v("Popularity"),
              ]),
              _c("vs-th", { attrs: { "sort-key": "order_status" } }, [
                _vm._v("Order Status"),
              ]),
              _c("vs-th", { attrs: { "sort-key": "price" } }, [
                _vm._v("Price"),
              ]),
              _c("vs-th", [_vm._v("Action")]),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }