var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-sidebar",
    {
      staticClass: "add-new-data-sidebar items-no-padding",
      attrs: {
        "click-not-close": "",
        "position-right": "",
        parent: "body",
        "default-index": "1",
        color: "primary",
        spacer: "",
      },
      model: {
        value: _vm.isSidebarActiveLocal,
        callback: function ($$v) {
          _vm.isSidebarActiveLocal = $$v
        },
        expression: "isSidebarActiveLocal",
      },
    },
    [
      _c(
        "div",
        { staticClass: "mt-6 flex items-center justify-between px-6" },
        [
          _c("h4", [
            _vm._v(
              _vm._s(
                Object.entries(this.data).length === 0 ? "ADD NEW" : "UPDATE"
              ) + " ITEM"
            ),
          ]),
          _c("feather-icon", {
            staticClass: "cursor-pointer",
            attrs: { icon: "XIcon" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                _vm.isSidebarActiveLocal = false
              },
            },
          }),
        ],
        1
      ),
      _c("vs-divider", { staticClass: "mb-0" }),
      _c(
        _vm.scrollbarTag,
        {
          key: _vm.$vs.rtl,
          tag: "component",
          staticClass: "scroll-area--data-list-add-new",
          attrs: { settings: _vm.settings },
        },
        [
          _c(
            "div",
            { staticClass: "p-6" },
            [
              _vm.dataImg
                ? [
                    _c(
                      "div",
                      {
                        staticClass:
                          "img-container w-64 mx-auto flex items-center justify-center",
                      },
                      [
                        _c("img", {
                          staticClass: "responsive",
                          attrs: { src: _vm.dataImg, alt: "img" },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "modify-img flex justify-between mt-5" },
                      [
                        _c("input", {
                          ref: "updateImgInput",
                          staticClass: "hidden",
                          attrs: { type: "file", accept: "image/*" },
                          on: { change: _vm.updateCurrImg },
                        }),
                        _c(
                          "vs-button",
                          {
                            staticClass: "mr-4",
                            attrs: { type: "flat" },
                            on: {
                              click: function ($event) {
                                return _vm.$refs.updateImgInput.click()
                              },
                            },
                          },
                          [_vm._v("Update Image")]
                        ),
                        _c(
                          "vs-button",
                          {
                            attrs: { type: "flat", color: "#999" },
                            on: {
                              click: function ($event) {
                                _vm.dataImg = null
                              },
                            },
                          },
                          [_vm._v("Remove Image")]
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "mt-5 w-full",
                attrs: { label: "Name", name: "item-name" },
                model: {
                  value: _vm.dataName,
                  callback: function ($$v) {
                    _vm.dataName = $$v
                  },
                  expression: "dataName",
                },
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("item-name"),
                      expression: "errors.has('item-name')",
                    },
                  ],
                  staticClass: "text-danger text-sm",
                },
                [_vm._v(_vm._s(_vm.errors.first("item-name")))]
              ),
              _c(
                "vs-select",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticClass: "mt-5 w-full",
                  attrs: { label: "Category", name: "item-category" },
                  model: {
                    value: _vm.dataCategory,
                    callback: function ($$v) {
                      _vm.dataCategory = $$v
                    },
                    expression: "dataCategory",
                  },
                },
                _vm._l(_vm.category_choices, function (item) {
                  return _c("vs-select-item", {
                    key: item.value,
                    attrs: { value: item.value, text: item.text },
                  })
                }),
                1
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("item-category"),
                      expression: "errors.has('item-category')",
                    },
                  ],
                  staticClass: "text-danger text-sm",
                },
                [_vm._v(_vm._s(_vm.errors.first("item-category")))]
              ),
              _c(
                "vs-select",
                {
                  staticClass: "mt-5 w-full",
                  attrs: { label: "Order Status" },
                  model: {
                    value: _vm.dataOrder_status,
                    callback: function ($$v) {
                      _vm.dataOrder_status = $$v
                    },
                    expression: "dataOrder_status",
                  },
                },
                _vm._l(_vm.order_status_choices, function (item) {
                  return _c("vs-select-item", {
                    key: item.value,
                    attrs: { value: item.value, text: item.text },
                  })
                }),
                1
              ),
              _c("vs-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: { required: true, regex: /\d+(\.\d+)?$/ },
                    expression: "{ required: true, regex: /\\d+(\\.\\d+)?$/ }",
                  },
                ],
                staticClass: "mt-5 w-full",
                attrs: {
                  "icon-pack": "feather",
                  icon: "icon-dollar-sign",
                  label: "Price",
                  name: "item-price",
                },
                model: {
                  value: _vm.dataPrice,
                  callback: function ($$v) {
                    _vm.dataPrice = $$v
                  },
                  expression: "dataPrice",
                },
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("item-price"),
                      expression: "errors.has('item-price')",
                    },
                  ],
                  staticClass: "text-danger text-sm",
                },
                [_vm._v(_vm._s(_vm.errors.first("item-price")))]
              ),
              !_vm.dataImg
                ? _c(
                    "div",
                    { staticClass: "upload-img mt-5" },
                    [
                      _c("input", {
                        ref: "uploadImgInput",
                        staticClass: "hidden",
                        attrs: { type: "file", accept: "image/*" },
                        on: { change: _vm.updateCurrImg },
                      }),
                      _c(
                        "vs-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.$refs.uploadImgInput.click()
                            },
                          },
                        },
                        [_vm._v("Upload Image")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "flex flex-wrap items-center p-6",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "vs-button",
            {
              staticClass: "mr-6",
              attrs: { disabled: !_vm.isFormValid },
              on: { click: _vm.submitData },
            },
            [_vm._v("Submit")]
          ),
          _c(
            "vs-button",
            {
              attrs: { type: "border", color: "danger" },
              on: {
                click: function ($event) {
                  _vm.isSidebarActiveLocal = false
                },
              },
            },
            [_vm._v("Cancel")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }